
import { store } from "./../store/index.js";
import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

let router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    //autenticacion
    {
      path: "/login",
      name: "Login",
      visible: false,
      component: () => import(/* webpackChunkName: "about" */ '@/pages/Login/Login.vue'),
      meta: {
        requiresAuth: false,
      },
    },


    {
      path: "/",
      component: () => import(/* webpackChunkName: "about" */ '@/pages/Menu.vue'),
      redirect: "/ficha",
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: "/ficha",
          name: "ficha",
          component: () => import(/* webpackChunkName: "about" */ '@/pages/Ficha.vue'),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lista_ingresos",
          name: "ListaIngresos",
          component: () => import(/* webpackChunkName: "about" */ '@/pages/ListaIngresos.vue'),
          meta: {
            requiresAuth: true,
          },
        },

        {
          path: "/lista_pacientes",
          name: "ListaPacientes",
          component: () => import(/* webpackChunkName: "about" */ '@/pages/ListaPacientes.vue'),
          meta: {
            requiresAuth: true,
          },
        },

      ],
    },

  ],
});
router.beforeEach((to, from, next) => {
  var requiresAuth =
    to != null && to.matched.some((record) => record.meta.requiresAuth) != null
      ? to.matched.some((record) => record.meta.requiresAuth)
      : false;
  if (requiresAuth) {
    //console.log(to);
    if (store.getters.isLoggedIn) {

      next()
      return
    } else {
      alert("Sin acceso");
      next('/login')
    }

  } else {
    //alert("Sin acceso");
    next()
  }
});
export default router;
