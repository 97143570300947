<template>
  <router-view> </router-view>
</template>

<script>
export default {};
/* export default {
  name: 'GraficoTorta',
  components: {
    GoogleChart
  }
}
import GoogleChart from './components/GoogleChart.vue' */
</script>
